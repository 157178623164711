/* General Styles */
.about-container {
  padding: 100px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  text-align: left;
  background-color: #121212;
  flex-wrap: wrap;
  justify-content: space-between;
}

.about-me-pic {
  border-radius: 10%;
  width: 500px;
  height: 600px;
  margin-top: 120px;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.1);
  max-width: 100%;
}

.about-title {
  font-size: 2.5rem;
  margin: 10px 0;
  color: #e0e0e0;
  font-family: "Quantico";
  text-align: center;
}

.skills-title,
.certifications-title,
.hobbies-title {
  font-size: 1.25rem; /* Slightly larger for readability */
  margin: 20px 0 10px;
  color: #e0e0e0;
  font-family: "Quantico";
}

.about-description {
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.6;
  font-size: 1rem;
  color: #e0e0e0;
  font-family: "Quantico";
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 20px auto;
  max-width: 600px;
}

li {
  font-size: 1rem;
  color: #d6d2d2;
  margin: 10px 0;
  font-family: "Quantico";
}

.about-text-content {
  max-width: 800px;
}

/* Media Queries for Responsiveness */

/* For tablets and below (max-width: 768px) */
@media (max-width: 768px) {
  .about-container {
    flex-direction: column;
    align-items: center; /* Centering the container */
    justify-content: center; /* Ensuring content is vertically centered */
    padding: 40px; /* Adding some padding */
  }

  .about-me-pic {
    width: 60%;
    height: auto; /* Allow height to scale proportionally */
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: none; /* Remove shadow for smaller screens */
    display: block; /* Ensuring the image is centered */
    margin-left: auto;
    margin-right: auto;
  }

  .about-text-content {
    text-align: center; /* Center the text content */
    padding: 0 10px; /* Adding some padding to prevent text from touching edges */
  }

  .about-title {
    font-size: 2.2rem; /* Smaller but still readable */
  }

  .skills-title,
  .certifications-title,
  .hobbies-title {
    font-size: 1.1rem;
  }

  .about-description,
  li {
    font-size: 1rem; /* Adjust for readability */
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    padding-right: 0;
    margin: 0 auto; /* Center the list */
  }

  li {
    font-size: 1rem;
    color: #d6d2d2;
    margin: 10px 0;
    font-family: "Quantico";
  }
}

/* For mobile devices (max-width: 480px) */
@media (max-width: 480px) {
  .about-title {
    font-size: 2rem;
  }

  .skills-title,
  .certifications-title,
  .hobbies-title {
    font-size: 1rem;
  }

  .about-description,
  li {
    font-size: 0.9rem;
    line-height: 1.4; /* Slightly tighter line-height for smaller screens */
  }

  .about-me-pic {
    width: 60%;
    height: auto;
    margin-top: 20px;
  }

  .about-text-content {
    padding-left: 0;
    text-align: center;
  }

  ul {
    padding-left: 0;
    padding-right: 0;
  }

  /* Reduce margins for small screens */
  .about-container {
    padding: 10px;
  }

  /* Adjust padding for list items on smaller screens */
  li {
    margin: 8px 0;
  }
}
