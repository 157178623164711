@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&family=Prata&family=Quantico:ital,wght@0,400;0,700;1,400;1,700&display=swap");

body {
  font-family: "Quantico", sans-serif;
  color: #e0e0e0;
}

.portfolio-title {
  font-size: 2rem;
  text-align: center;
  margin: 30px 0 20px;
  color: #e0e0e0;
}

.projects-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin: 0 auto;
}

.project-card {
  border: 1px solid rgb(255, 255, 255);
  border-radius: 10px;
  padding: 20px;
  max-width: 500px;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.1);
  text-align: center;
  flex: 1 1 calc(33% - 40px);
}

.project-image {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 15px;
}

.project-title {
  font-size: 1.5rem;
  color: #e0e0e0;
  margin-bottom: 10px;
}

.project-description {
  line-height: 1.6;
  color: #d6d2d2;
  font-size: 1rem;
  margin: 10px 0;
}

.project-link {
  color: #e0e0e0;
  text-decoration: none;
  font-weight: bold;
}

.project-link:hover {
  text-decoration: underline;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .project-card {
    flex: 1 1 calc(50% - 40px);
  }
}

@media (max-width: 480px) {
  .project-card {
    flex: 1 1 100%;
    max-width: 90%;
  }

  .portfolio-title {
    font-size: 1.5rem;
  }

  .project-title {
    font-size: 1.0rem;
  }

  .project-description {
    font-size: 0.6rem;
  }

  .project-link {
    font-size: 0.8rem;
  }
}
