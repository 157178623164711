@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&family=Prata&family=Quantico:ital,wght@0,400;0,700;1,400;1,700&display=swap");

body {
  font-family: "Quantico", sans-serif;
  background-color: #121212;
  color: #e0e0e0;
  margin: 0;
  padding: 0;
}

.contact-title {
  text-align: center;
  font-size: 2rem;
  color: #e0e0e0;
  margin: 30px 0 20px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.1);
}

.form-input,
.form-textarea {
  width: 100%;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.05);
  color: #e0e0e0;
  font-family: "Quantico", sans-serif;
}

.form-input::placeholder,
.form-textarea::placeholder {
  color: #d6d2d2;
}

.form-textarea {
  resize: none;
  height: 100px;
}

.submit-button {
  background-color: #2980b9;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #3498db;
}

.success-message,
.error-message {
  text-align: center;
  margin: 10px 0;
  width: 100%;
}

.success-message {
  color: #2ecc71;
}

.error-message {
  color: #e74c3c;
}

.message-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .contact-form {
    max-width: 90%;
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .contact-title {
    font-size: 1.8rem;
  }

  .submit-button {
    padding: 10px;
  }
}
