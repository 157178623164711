@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&family=Prata&family=Quantico:ital,wght@0,400;0,700;1,400;1,700&display=swap");

div {
  padding: 2rem;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  padding: 1rem 2rem;
  box-shadow: none;
  flex-wrap: wrap;
}

.menu-icon {
  display: none;
  cursor: pointer;
}

.nav-links {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

nav a {
  color: #e0e0e0;
  text-decoration: none;
}

.nav-link {
  text-decoration: none;
  color: #ffffff;
  font-size: 1.1rem;
  margin: 0 1rem;
  position: relative;
  transition: color 0.3s ease;
  font-family: "Quantico", sans-serif;
}

.active-link {
  color: #a0a3a9;
  font-weight: bold;
}

.nav-link:hover {
  color: #a0a3a9;
  text-decoration: none;
}

.nav-link::after {
  content: "";
  display: block;
  height: 2px;
  width: 0;
  background: #a0a3a9;
  transition: width 0.3s ease;
  position: absolute;
  left: 50%;
  bottom: -5px;
  transform: translateX(-50%);
}

.nav-link:hover::after,
.active-link::after {
  width: 100%;
}

@media (max-width: 768px) {
  div {
    padding: 1rem;
  }
  .navbar {
    flex-direction: column;
    align-items: flex-end;
  }

  .menu-icon {
    display: block;
    margin-left: auto;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
  }

  .nav-links.active {
    display: flex;
  }

  .nav-link {
    margin: 0.5rem 0;
    text-align: right;
  }
}
