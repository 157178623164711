@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&family=Prata&family=Quantico:ital,wght@0,400;0,700;1,400;1,700&display=swap");

body {
  font-family: "Quantico", sans-serif;
  color: #e0e0e0;
}

.education-title {
  font-size: 2rem;
  text-align: center;
  margin: 30px 0 20px;
  color: #e0e0e0;
}

.education-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.degree,
.bootcamp {
  border: 1px solid rgb(255, 255, 255);
  border-radius: 10px;
  padding: 20px;
  max-width: 500px;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.1);
  flex: 1 1 auto;
  margin: 0;
}

.degree-title,
.bootcamp-title {
  font-size: 1.5rem;
  color: #e0e0e0;
  margin-bottom: 10px;
}

.gpa {
  font-size: 1.2rem;
  color: #d6d2d2;
  margin-bottom: 10px;
}

.coursework-title {
  font-size: 1.2rem;
  color: #d6d2d2;
  margin-bottom: 10px;
}

.coursework-list {
  list-style-type: disc;
  padding-left: 20px;
  margin: 20px auto;
  max-width: 600px;
}

li {
  font-size: 1rem;
  color: #d6d2d2;
  margin: 5px 0;
}

.bootcamp-summary {
  line-height: 1.6;
  color: #e0e0e0;
  font-size: 1rem;
  max-width: 600px;
  margin: 10px auto;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .education-container {
    flex-direction: column;
    align-items: center;
  }

  .degree,
  .bootcamp {
    max-width: 90%;
  }
}

@media (max-width: 480px) {
  .education-title {
    font-size: 1.5rem;
  }

  .degree-title,
  .bootcamp-title {
    font-size: 1.0rem;
  }

  .gpa,
  .coursework-title,
  .bootcamp-summary,
  li {
    font-size: 0.6rem;
  }
}
