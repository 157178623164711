@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&family=Prata&family=Quantico:ital,wght@0,400;0,700;1,400;1,700&display=swap");

.home-header {
  padding: 100px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.8s forwards;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.profile-picture {
  border-radius: 50%;
  width: 300px;
  height: 300px;
  margin-bottom: 20px;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.1);
  transition: transform 0.3s ease;
}

.name {
  font-size: 2.5rem;
  margin: 10px 0;
  color: #e0e0e0;
  font-family: "Quantico";
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.8s forwards 0.4s;
}

.bio {
  max-width: 500px;
  margin: 0 auto;
  line-height: 1.6;
  font-size: 1rem;
  color: #e0e0e0;
  font-family: "Quantico";
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.8s forwards 0.6s;
}

.social-icons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.social-icon img {
  width: 40px;
  height: 40px;
  margin: 0 10px;
  transition: transform 0.3s, opacity 0.3s;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.8s forwards 0.8s;
}

.social-icon img:hover {
  opacity: 1;
  transform: scale(1.2);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .home-header {
    padding: 50px 10px;
  }

  .profile-picture {
    width: 200px;
    height: 200px;
  }

  .name {
    font-size: 2rem;
  }

  .bio {
    font-size: 0.9rem;
    max-width: 90%;
  }
}

@media (max-width: 480px) {
  .home-header {
    padding: 30px 5px;
  }

  .profile-picture {
    width: 150px;
    height: 150px;
  }

  .name {
    font-size: 1.8rem;
  }

  .bio {
    font-size: 0.8rem;
  }

  .social-icon img {
    width: 30px;
    height: 30px;
  }
}
